export default class LicenseInbox {
  constructor(objIn = {}) {
    const defaults = {
      BusinessID: null,
      //LicenseID is required
      LicenseID: 0,
      HempLicenseID: null,
      BusinessName: null,
      LicenseType: null,
      LicenseFee: null,
      ExpirationDate: null,
      StatusUser: null,
      StatusDate: null,
      Status: null,
      LicenseSubTypes: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.BusinessID = obj.BusinessID
    this.LicenseID = obj.LicenseID
    this.HempLicenseID = obj.HempLicenseID
    this.BusinessName = obj.BusinessName
    this.LicenseType = obj.LicenseType
    this.LicenseFee = obj.LicenseFee
    this.ExpirationDate = obj.ExpirationDate
    this.StatusUser = obj.StatusUser
    this.StatusDate = obj.StatusDate
    this.Status = obj.Status
    this.LicenseSubTypes = obj.LicenseSubTypes
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    return obj
  }
}
