<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <LicenseInboxGrid></LicenseInboxGrid>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LicenseInboxGrid from '@components/search/LicenseInbox'
export default {
  page: {
    title: '',
  },
  components: {
    LicenseInboxGrid,
  },
  data() {
    return {}
  },
  created() {
    this.loadBusiness()
  },
  computed: {},
  methods: {
    loadBusiness: call('business/loadBusiness'),
  },
  watch: {},
}
</script>
<style scoped></style>
