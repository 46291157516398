<template>
  <div>
    <v-card class="mx-auto">
      <v-list-item class="primary">
        <v-list-item-content>
          <v-list-item-title class="headline text-left white--text"
            >License Inbox</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row justify="end">
          <v-col sm="auto" justify="end" class="mt-4">
            <v-switch v-model="showAllLicenses" label="View All"></v-switch>
          </v-col>
          <v-col sm="auto">
            <v-text-field
              v-model="searchText"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              clearable
            >
            </v-text-field>
          </v-col>
          <!-- <v-col sm="auto" class="mt-4 mr-4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  v-on="on"
                  fab
                  @click="addLicenseInbox"
                  depressed
                  small
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add LicenseInbox</span>
            </v-tooltip>
          </v-col> -->
        </v-row>
      </v-card-text>
      <template>
        <v-data-table
          :headers="filteredHeaders"
          :server-items-length="searchTotal"
          :items="licenseInbox"
          :sort-by.sync="searchSortBy"
          :sort-desc.sync="searchDescending"
          :page.sync="searchPage"
          :search="searchText"
          :items-per-page.sync="searchRowsPerPage"
          class="pt-4"
          :loading="loading"
          dense
          :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 25, 50, 100, ],
        }"
        >
          <template v-slot:item.viewLicense="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  icon
                  color="primary"
                  :to="
                    `/hempApplication/${item.BusinessID}/${item.HempLicenseID}`
                  "
                  v-on="on"
                >
                  <v-icon class="clickable" color="primary">mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>View License Info</span>
            </v-tooltip>
          </template>
          <template v-slot:item.viewBusiness="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  icon
                  color="primary"
                  :to="`/userBusiness/${item.BusinessID}`"
                  v-on="on"
                >
                  <v-icon class="clickable" color="primary">mdi-bank</v-icon>
                </v-btn>
              </template>
              <span>View Business Info</span>
            </v-tooltip>
          </template>
          <template v-slot:item.ExpirationDate="{ item }">
            {{ item.ExpirationDate | dateformat }}
          </template>
        </v-data-table>
      </template>
    </v-card>
  </div>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LicenseInbox from '@classes/LicenseInbox'
//templateTODO: import lookup components you plan to use
export default {
  page: {
    title: 'LicenseInbox',
  },
  data: () => ({
    valid: true,
    selectedLicenseInbox: new LicenseInbox(),
    dialog: false,
    deleteDialog: false,
    modalText: '',
  }),
  created() {
    this.searchLicenseInbox()
  },
  computed: {
    ...get('licenseInbox', ['licenseInbox', 'saving', 'loading']),
    ...sync('licenseInbox', ['showAllLicenses', 'searchSortBy', 'searchDescending', 'searchRowsPerPage', 'searchPage', 'searchTotal', 'searchText']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'viewLicense',
          visible: true,
          sortable: false,
        },
        {
          text: '',
          align: 'center',
          value: 'viewBusiness',
          visible: true,
          sortable: false,
        },
        {
          text: 'Business I D',
          align: 'center',
          sortable: true,
          value: 'BusinessID',
          visible: false,
        },
        {
          text: 'License I D',
          align: 'center',
          sortable: true,
          value: 'LicenseID',
          visible: false,
        },
        {
          text: 'Hemp License I D',
          align: 'center',
          sortable: true,
          value: 'HempLicenseID',
          visible: false,
        },

        {
          text: 'Business Name',
          align: 'center',
          sortable: true,
          value: 'BusinessName',
          visible: true,
        },
        {
          text: 'License Type',
          align: 'center',
          sortable: true,
          value: 'LicenseType',
          visible: true,
        },
        {
          text: 'License Sub Types',
          align: 'center',
          sortable: true,
          value: 'LicenseSubTypes',
          visible: true,
        },
        {
          text: 'License Fee',
          align: 'center',
          sortable: true,
          value: 'LicenseFee',
          visible: false,
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'Status',
          visible: true,
        },
        {
          text: 'Expiration Date',
          align: 'center',
          sortable: true,
          value: 'ExpirationDate',
          visible: true,
        },
        {
          text: 'Status User',
          align: 'center',
          sortable: true,
          value: 'StatusUser',
          visible: false,
        },
        {
          text: 'Status Date',
          align: 'center',
          sortable: true,
          value: 'StatusDate',
          visible: false,
        },

        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('licenseInbox', [
      'saveLicenseInbox',
      'deleteLicenseInbox',
      'loadLicenseInbox',
      'clearLookupCacheAndReloadLicenseInbox',
    ]),
    ...call('licenseInbox', ['searchLicenseInbox']),
    async validate() {
      this.$refs.licenseInboxForm.validate()
    },
    editLicenseInbox(entry) {
      this.selectedLicenseInbox = new LicenseInbox(entry)
      this.modalText = 'Edit LicenseInbox'
      this.dialog = !this.dialog
    },
    addLicenseInbox() {
      this.modalText = 'Insert LicenseInbox'
      this.selectedLicenseInbox = new LicenseInbox()
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedLicenseInbox = item
      this.deleteDialog = true
    },
    callDelete() {
      this.deleteLicenseInbox(this.selectedLicenseInbox).then(
        (res) => {
          this.clearLookupCacheAndReloadLicenseInbox()
          this.deleteDialog = false
        },
        (err) => {}
      )
    },
    saveLicenseInboxDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveLicenseInbox(this.selectedLicenseInbox.removeRelated()).then(
            (res) => {
              this.clearLookupCacheAndReloadLicenseInbox()
              this.selectedLicenseInbox = new LicenseInbox()
              this.$refs.licenseInboxForm.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLicenseInbox = new LicenseInbox()
      this.$refs.licenseInboxForm.resetValidation()
    },
  },
}
</script>
<style></style>
